///<reference path="typings.d.ts" />
var results: results = {
    result: 0,
    sodium: 0,
    potassium: 0,
    urea: 0,
    glucose: 0
}

const high_low = {
    result: {
        good: 295,
        bad: 300
    },
    sodium: {
        low: 133,
        high: 146
    },
    potassium: {
        low: 3.5,
        high: 5.3
    },
    urea: {
        low: 2.5,
        high: 7.8
    },
    glucose: {
        low: 4,
        high: 11.1
    },
    bmi: {
        low: 18,
        high: 25
    }
}

$( document ).ready( () => {
    // Show the form when the page loads
    $( "#test" ).removeClass( "d-none" )

    $( "input[name=alcohol]" ).on( "change", () => {
        if ( $( "input[name=alcohol]:checked" ).val() == "1" ) {
            $( "#ethanol-group" ).collapse( "show" )
            $( "#ethanol" ).prop( "required", true )
        } else {
            $( "#ethanol-group" ).collapse( "hide" )
            $( "#ethanol" ).prop( "required", false )
        }
    } )

    // On form submit
    $( "#submit" ).click( ( event ) => {
        event.preventDefault()

        if ( $( 'form input' ).bootstrap_validate() ) {
            console.log( '%cPassed valudation', 'color:green;' )

            let values: test_values = {}

            $( "#test form input" ).serializeArray().map( ( x ) => {
                if ( isNaN( parseFloat( x.value ) ) ) {
                    return
                }

                values[ x.name ] = parseFloat( x.value )
            } )

            //values.alcohol = Boolean( values.alcohol )

            // Do the calculation
            results.result = osmo_calc( values )

            results.result = parseFloat( results.result.toFixed( 3 ) )


            // Check wether the BMI calculation needs doing. 
            if ( "height" in values && values.height > 0 && "weight" in values && values.weight > 0 ) {
                let height = values.height / 100,
                    bmi = values.weight / ( height * height )

                results.bmi = parseFloat( bmi.toFixed( 2 ) )
                results.intake = values.weight * 30
            }

            results = Object.assign( results, values )

            for ( let key in results ) {
                if ( key != "alcohol" ) {
                    $( `#osmo-${key}` ).text( results[ key ] ).parents( ".row" ).removeClass( "d-none" )

                    if ( key in high_low ) {
                        $( `#osmo-${key}` ).parent().addClass( high_low_class( key, results[ key ] ) )
                    }
                } else {
                    $( `#osmo-${key}` ).text( ( results[ key ] ) ? "Yes" : "No" ).parents( ".row" ).removeClass( "d-none" )
                }
            }

            $( "#test" ).addClass( "d-none" )
            $( "#results" ).removeClass( "d-none" )
        } else {
            console.log( "%cValidation failed", "color:red;" )
        }
    } )

} )

var high_low_class = ( key: string, value: number ) => {
    let modifier_class: string = ""

    if ( key in high_low ) {
        if ( key !== "result" ) {
            if ( value <= high_low[ key ].low || value >= high_low[ key ].high ) {
                modifier_class = "text-danger"
            } else {
                modifier_class = "text-success"
            }
        } else {
            if ( value <= high_low[ key ].good ) {
                modifier_class = "text-success"
            } else if ( high_low[ key ].good < value && value < high_low[ key ].bad ) {
                modifier_class = "text-warning"
            } else if ( high_low[ key ].bad <= value ) {
                modifier_class = "text-danger"
            }
        }
    }

    return modifier_class
}

if ( "serviceWorker" in navigator ) {
    window.addEventListener( "load", () => {
        navigator.serviceWorker.register( "/service-worker.js" )
    } )
}
